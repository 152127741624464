import React from 'react';
import "./getintouch.css"

function GetInTouch({title}) {
    return (
        <div className='get-in-touch'>
            <div className='get-in-topic'>{title}</div>
        </div>
    );
}

export default GetInTouch;